<template>
  <div
    :style="color ? {'--ui-color-primary': color} : null"
    class="ui-tab ui-clickable ui-noselect"
    :class="{ '--selected': $parent.value == value }"
    @click="onClick"
  >
    <slot>
      <ui-item
        class="--nowrap"
        v-bind="itemAttrs"
        :icon-color="$parent.value == value ? color || 'var(--ui-color-primary)' : '#999'"
      ></ui-item>
    </slot>
  </div>
</template>

<script>
import { UiItem } from '@/modules/ui/components';

export default {
  name: 'ui-tab',
  components: { UiItem },

  data() {
    return {};
  },

  props: {
    value: {
      required: false,
      default: null
    },

    label: {
      type: String,
      required: false,
      default: null
    },

    color: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    itemAttrs() {
      return Object.assign({ text: this.label }, this.$attrs);
    }
  },

  methods: {
    onClick() {
      this.$parent.selectTab(this);
    }
  }
};
</script>

<style lang="scss">
.ui-tab {
  position: relative;
  padding: 4px var(--ui-padding-horizontal);

  font-family: var(--ui-font-secondary);
  font-size: 14px;

  // clase de ui-item
  .text-primary {
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold !important;
  }

  --tab-anchor: right;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    border-radius: 2px;
    background-color: var(--ui-color-primary);
    transform: scaleX(0);
    transform-origin: bottom var(--tab-anchor);

    transition: transform var(--ui-duration-snap);
  }

  &.--selected {
    &::after {
      transform: scaleX(1);
    }
  }
}
</style>
